import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MdOutlineContactPage } from "react-icons/md"
import MTNlogo from "../../assets/par_mtn.png";
import Airtellogo from "../../assets/par_airtel.png";
import GLOlogo from "../../assets/par_glo.png";
import Mobilelogo from "../../assets/par_9mobile.png";
import { AuthUserContext } from '../../context/auth_user_state';
import { NetworkContext } from '../../context/network_context';
import CustomSelect from '../../components/ImageSelect';
import axios from 'axios';
import { baseUrl } from '../..';
import { AlertContext } from '../../context/alert_state';
import ConfirmTransaction from '../../alerts/ConfirmTransaction';
import Predicter from '../../components/Predicter';



const theme = createTheme({
  palette: {
    primary: {
      light: "#cc300d",
      main: "#cc300d"
    },
    secondary: {
      main: "#cc300d",
      light: "#cc300d"
    }
  },
});


// interface NetworkInterface {
//   image: string,
//   label: string,
//   id: string
// }
// const allNetworks: NetworkInterface[] = [{ id: "1", image: MTNlogo, label: "MTN" },
// { id: "2", image: GLOlogo, label: "GLO" }, { id: "3", image: Airtellogo, label: "Airtel" }, { id: "4", image: Mobilelogo, label: "9Mobile" }
// ]

const SubClass = () => {


  type ValueType = string | null;
  const [value, setValue] = useState<ValueType>(null);
  const [inputValue, setInputValue] = useState('');
  const { authUser } = useContext(AuthUserContext)
  const [incompleteError, setIncompleteError] = useState(false)
  const { networks, setNetworks } = useContext(NetworkContext);

  const suggestions: string[] = authUser?.recentCreditedNumber ?? [];
  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState(0)
  const { alert, setAlert } = useContext(AlertContext);

  const buyData = async () => {

    return await axios.post(baseUrl + "/pricing/payment/data", {
      "networkID": networks![currentIndex].networkID,
      "planID": networks![currentIndex].dataPlans[selectedPlan].planID,
      "mobileNo": value?.toString().trim() ?? inputValue.trim()
    },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    )
  }

  const handlePurchaseData = async () => {

    if (setAlert) setAlert({
      showAlert: true,
      alertContent: <ConfirmTransaction callback={buyData} price={networks![currentIndex].dataPlans[selectedPlan].price} header={networks![currentIndex].dataPlans[selectedPlan].displayName} details={<p>

        Are you sure you want to buy data for <span className='font-bold'>{value?.toString().trim() ?? inputValue.trim()}</span>
      </p>} />,
      alertDismissible: false
    })
  }




  return (
    <ThemeProvider theme={theme}>
      <div id='home' className='w-[98vw] pt-[20px] pb-[20px]'>
        <div className='flex flex-col  w-full  lg:max-w-5xl  md:max-w-[540px]  mx-auto my-2 md:px-0 px-[15px]'>
          <div className='flex md:flex-row flex-col justify-start  md:justify-between  w-full md:items-end items-start'>
            <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>Data Subscription</p>
            <p>Available Balance: <span className='text-connect-red'># {parseFloat(authUser?.walletBalance?.toString() ?? "0").toLocaleString("en-Us")}</span></p>
          </div>
          <div className='md:my-5 mt-2 flex w-[96%] items-start md:items-center justify-end '>
            <CustomSelect onChange={(value, index) => {
              setSelectedPlan(0)
              setCurrentIndex(networks?.findIndex(currValue => currValue.networkID.toString() === value) ?? 0);
            }} />
            <Autocomplete
              className='w-full px-4'
              freeSolo
              options={suggestions}
              value={value}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={(event, newValue) => {

                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder='Insert Phone Number'
                  variant="standard" />
              )}
            />

            <MdOutlineContactPage size={30} className='text-connect-red hidden md:inline' />
          </div>
          <Predicter value={value?.toString() ?? inputValue} />


          {/* Data Cards */}
          <div className='grid  lg:grid-cols-5 grid-cols-3 justify-center '>
            {networks !== undefined ? networks![currentIndex].dataPlans.map((e, index) => <div id={e._id} className={`md:w-[160px] w-[90px] h-[110px] md:h-[180px] shadow-md p-3 rounded-md m-3 border border-connect-red flex flex-col items-center justify-evenly transition-none duration-500 cursor-pointer ${index === selectedPlan ? "bg-connect-red text-white" : ""}`} onClick={(e) => {
              setSelectedPlan(index)
            }}>
              <p className={"md:text-lg text-xs text-center font-bold"}>{e.displayName}</p>
              <p className='text-xs md:text-base'>{e.validity}</p>
              <p className='text-xs md:text-base font-bold'># {parseFloat(e.price.toString()).toLocaleString("en-US")}</p>
            </div>) : <div>Loading</div>}

          </div>

          <div className='mt-10 cursor-pointer'>
            {incompleteError && <p className='my-2 text-connect-red text-xs'>Number must be 11 digits </p>}
            <p className='md:max-w-[500px] max-w-[250px] text-center rounded-2xl mx-auto py-3 bg-yellow' onClick={(e) => {
              if (value?.toString().trim().length === 11 || inputValue.trim().length === 11) {
                handlePurchaseData()
              } else {
                setIncompleteError(true)
              }

            }} >Conitnue</p>
          </div>

        </div>
        <div>

        </div>


      </div>
    </ThemeProvider>
  )
}

export default SubClass